import Vue from 'vue'
import Breakpoints from '../vue/breakpoints'
import LazyLoad from "vanilla-lazyload";
import MmenuLight from  '../../node_modules/mmenu-light/src/mmenu-light.js';
import ScrollOut from "scroll-out";

// Intiate Vuejs instance
// var app = new Vue({
//     el: '#app',
//     delimiters: ["${","}"],
//     components: {
//         Breakpoints,
//     }
// })

// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {

    //Location call us pop up
    let callUs = document.querySelectorAll('.call-us');
    let locationPopupContainer = document.querySelector('.location__popup');
    let modalOverlay = document.querySelector('.modal--overlay');
    let modalOverlayClose = document.querySelector('.modal--overlay__close');

    callUs.forEach(item => 
        item.addEventListener('click', function() {
            locationPopupContainer.classList.remove('modal--hide');
            modalOverlay.classList.remove('modal--hide');
        })
    )
    modalOverlay.addEventListener('click', function() {
        locationPopupContainer.classList.add('modal--hide');
        this.classList.add('modal--hide');
    })
    modalOverlayClose.addEventListener('click', function() {
        locationPopupContainer.classList.add('modal--hide');
        modalOverlay.classList.add('modal--hide');
    })

    ScrollOut({ 
        once: true
    });

    //Mobile menu init // Sync search and menu open
    const menu = new MmenuLight( document.querySelector( "#mobile-menu" ), "(max-width: 1280px)" );

    const navigator = menu.navigation();
    const drawer = menu.offcanvas();

    const navTrigger = document.querySelector('.js-open-menu');
    const projectWrapper = document.getElementById('app');

    // Toggle Menu
    bindEvent(navTrigger, "click", function() {
        this.classList.toggle('is-active');
        projectWrapper.classList.toggle('js-is-open');

        if (this.classList.contains('is-active')) {
            drawer.open();
        } else {
            drawer.close();
        }
        console.log('click');
    }, false);
    
    
    // LAZYLOAD
    var lazy = new LazyLoad({
        elements_selector: ".lazy"
    });

    const dropdowns = document.querySelectorAll('.js-filter-select');
    for (var n = 0; n < dropdowns.length; n++) {
        const dropdown = dropdowns[n];
        dropdown.addEventListener('click', function(e) {
            console.log('test test');
            e.stopPropagation();
            return false;
        });
    }

    // OVERLAY FUNCTIONALITY
    const overlayTriggers = document.querySelectorAll('.js-open-overlay');
    const app = document.getElementById('app');

    if(overlayTriggers.length){ 
        for (var i = 0; i < overlayTriggers.length; i++) {
            const overlayTrigger = overlayTriggers[i];
            const overlayTeam = document.getElementById(overlayTrigger.getAttribute('data-id'));
            const close = overlayTeam.querySelector('.js-close-modal');
            overlayTrigger.addEventListener('click',  function(e) {
                e.stopPropagation();
                overlayTeam.classList.add('show');

                const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
                app.style.position = 'fixed';
                app.style.top = `-${scrollY}`;

                return false;
            });

            close.addEventListener('click', function(e) {
                e.stopPropagation();

                overlayTeam.classList.remove("show");

                const scrollY = app.style.top;
                app.style.position = '';
                app.style.top = '';
                window.scrollTo(0, parseInt(scrollY || '0') * -1);

                return false;
            });

            //Closes the modal when the document is clicked
            overlayTeam.addEventListener('click', () => {
                overlayTeam.classList.remove('show');

                const scrollY = app.style.top;
                app.style.position = '';
                app.style.top = '';
                window.scrollTo(0, parseInt(scrollY || '0') * -1);

            });
        }

        //Prevents the click event from reaching the document
        app.addEventListener('click', (e) => {
            e.stopPropagation();
        });

        // for (var k = 0; k < modals.length; i++) {
        //     const modal = modals[k];
        //     modal.addEventListener('click', (e) => {
        //         e.stopPropagation();
        //     });
        // }

        window.addEventListener('scroll', () => {
            document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
        });
    }

    // ACCORDIONS
    const accordionItems = document.querySelectorAll('[data-accordion-item]');
    const accordionContentPanes = document.querySelectorAll('[data-accordion-content]');
    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionTitleRow = accordion.firstElementChild;
            bindEvent(accordionTitleRow, 'click', toggleAccordion);
        });
    } 

    function toggleAccordion(e) {
    
        if(e.target.parentElement.classList.contains('accordion-item--active')) {
            e.target.parentElement.classList.remove('accordion-item--active');
            return;
        }
        accordionContentPanes.forEach(function(content) {
            if (content.previousElementSibling === e.target) {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('accordion-item--active');
                }
                content.parentElement.classList.add('accordion-item--active');
            } else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.parentElement.classList.remove('accordion-item--active');
                }
                content.classList.add('accordion-item--active');
            }
        });
    }

    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener){
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent){
            el.attachEvent('on'+eventName, eventHandler);
        }
    }

    // File input custom styling
    let fileInputs = document.querySelectorAll( '.inputfile' );

    if(fileInputs.length != null) {
        Array.prototype.forEach.call( fileInputs, function( input ) {
            let label	 = input.nextElementSibling,
                labelVal = label.innerHTML;

            input.addEventListener( 'change', function( e ) {
                let fileName = '';
                if( this.files && this.files.length > 1 ) {
                    fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
                }    
                else {
                    fileName = e.target.value.split( '\\' ).pop();
                }
                if( fileName ) {
                    label.innerHTML = fileName.slice(0,10) +  "...";
                } 
                else {
                    label.innerHTML = labelVal;
                }
            });
        });
    }

})